@tailwind base;
@tailwind components;
@tailwind utilities;

@import './editor.css';
@import './index.css';



@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }

  :focus-visible {
    @apply outline-none;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}


body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: hsl(var(--background));
}
body::-webkit-scrollbar-thumb {
  background-color: hsl(var(--foreground));
  border-radius: 6px;
  --webkit-font-smoothing: antialiased;
}

body::before {
  content: '';
  position: fixed;
  inset: 0;
  background: url('/images/gradient-noise-green-red.png') no-repeat center
    center;
  background-size: cover;
  filter: invert(1);
  opacity: 0.05;
}
body {
  overflow-x: hidden;
}


.react-colorful {
  width: 100% !important;
}

[data-reference-hidden] {
  opacity: 0;
  pointer-events: none;
}

::-webkit-scrollbar {
  @apply w-1 h-1 bg-neutral-500/20;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-500/50 rounded-full;
}

input[type='range'] {
  @apply h-2.5 bg-neutral-200 border-0 rounded appearance-none active:bg-neutral-300 transition-all;
  @apply dark:bg-neutral-700 dark:active:bg-neutral-600;

  &::-webkit-slider-thumb {
    @apply appearance-none w-3 h-5 bg-neutral-800 rounded-full active:bg-neutral-900 active:w-4 active:h-6 transition-all;
    @apply dark:bg-neutral-100 dark:active:bg-white;
  }
}


/* photoswipe overrides */
.pswp__button--zoom,
.pswp__button--close {
  display: none !important;
}
.pswp__custom--button {
  display: flex;
  align-items: center;
}

/* img-comparison-slider overrides */
.img-comparison-slider {
  --default-handle-shadow: 0px 0px 5px ${colors.black}80;
  --divider-shadow: 0px 0px 5px ${colors.black}80;
}
.img-comparison-slider:focus {
  outline: none;
}


.rc-mentions {
  display: inline-block;
  position: relative;
  white-space: pre-wrap;
}
.rc-mentions > textarea, .rc-mentions-measure {
  font-size: inherit;
  font-size-adjust: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  font-weight: inherit;
  font-family: inherit;
  padding: 0;
  margin: 0;
  line-height: inherit;
  vertical-align: top;
  overflow: inherit;
  word-break: inherit;
  white-space: inherit;
  word-wrap: break-word;
  overflow-x: initial;
  overflow-y: auto;
  text-align: inherit;
  letter-spacing: inherit;
  tab-size: inherit;
  direction: inherit;
}
.rc-mentions > textarea {
  border: none;
  width: 100%;
}
.rc-mentions-measure {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  color: transparent;
  z-index: -1;
}
.rc-mentions-dropdown {
  position: absolute;
}
.rc-mentions-dropdown-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rc-mentions-dropdown-menu-item {
  cursor: pointer;
}
.rc-mentions {
  font-size: 20px;
  border: 1px solid #999;
  border-radius: 3px;
  overflow: hidden;
}
.rc-mentions-dropdown {
  border: 1px solid #999;
  border-radius: 3px;
  background: #FFF;
}
.rc-mentions-dropdown-menu-item {
  padding: 4px 8px;
}
.rc-mentions-dropdown-menu-item-active {
  background: #e6f7ff;
}
.rc-mentions-dropdown-menu-item-disabled {
  opacity: 0.5;
}
