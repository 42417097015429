.ProseMirror {
  .collaboration-cursor {
    &__caret {
      @apply border-r border-l border-black pointer-events-none -ml-[1px] -mr-[1px] relative break-normal;
    }

    &__label {
      @apply rounded text-black text-xs font-semibold -left-[1px] leading-none py-0.5 px-1.5 absolute -top-[1.4em] select-none whitespace-nowrap;
    }
  }
}
