.ProseMirror {
  p {
    /* @apply leading-relaxed my-3 first:mt-0 last:mb-0; */
    @apply leading-relaxed my-4 first:mt-0 last:mb-0;
  }

  & > p {
    /* @apply my-6 first:mt-0 last:mb-0; */
  }

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }

  h4 {
    @apply text-lg;
  }

  h5 {
    @apply text-base;
  }

  h6 {
    @apply text-sm;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* @apply font-bold first:mt-0 last:mb-0; */
    @apply first:mt-0 last:mb-0;
    @apply mb-2 mt-6 leading-tight;
  }

  h1,
  h2,
  h3 {
    /* @apply mt-12; */
  }

  h4,
  h5,
  h6 {
    /* @apply mt-8; */
  }

  a.link {
    /* @apply text-blue-500 font-extrabold dark:text-blue-400; */
    @apply text-blue-500 dark:text-blue-400;
  }

  mark {
    @apply bg-red-500 rounded-sm decoration-clone text-inherit py-1 px-0 dark:bg-red-400;
  }

  & img {
    @apply h-auto w-full max-w-full;
  }

  [data-type='horizontalRule'] {
    /* @apply my-8 py-4 transition-all duration-100 ease-in-out cursor-pointer; */
    @apply py-4 transition-all duration-100 ease-in-out cursor-pointer;

    &.ProseMirror-selectednode {
      @apply bg-black/5 dark:bg-white/10;

      hr {
        @apply border-t-black/30 dark:border-t-white/30;
      }
    }

    &:hover:not(&.ProseMirror-selectednode) {
      @apply bg-black/5;
      @apply dark:bg-white/10;
    }

    hr {
      /* @apply border-0 border-t border-black/20 bg-black/80; */
      @apply border-0 border-t border-black/20 ;
      /* @apply dark:border-white/20 dark:bg-white/80; */
      @apply dark:border-white/20 ;
    }
  }

  [data-type='emoji'] {
    display: inline-block;

    img {
      width: 1em;
      height: 1em;
      object-fit: cover;
      display: block;
    }
  }
}
